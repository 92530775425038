import React from 'react';
import '../style.css';

const AnimatedLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="100%"
    height="auto"
    viewBox="0 0 1500 350"
    preserveAspectRatio="xMidYMid meet"
    className="logo"
    {...props}
  >
    <g id="hexagon">
      <line className="logo-line" x1="22.4" y1="174" x2="98.5" y2="41.7" />
      <line className="logo-line" x1="98.5" y1="41.7" x2="250.6" y2="41.7" />
      <line className="logo-line" x1="250.6" y1="41.7" x2="326.7" y2="173.5" />
      <line className="logo-line" x1="326.7" y1="173.5" x2="250.6" y2="305.3" />
      <line className="logo-line" x1="250.6" y1="305.3" x2="98.5" y2="305.3" />
      <line className="logo-line" x1="98.5" y1="305.3" x2="22.4" y2="173.5" />
    </g>
    <g id="line">
      <line className="logo-line" x1="137.9" y1="237" x2="211.2" y2="110" opacity="0">
        <animate attributeName="opacity" from="0" to="1" dur="0.5s" begin="2s" fill="freeze" />
        <animate attributeName="x2" from="137.9" to="211.2" dur="1s" begin="2s" fill="freeze" />
        <animate attributeName="y2" from="237" to="110" dur="1s" begin="2s" fill="freeze" />
      </line>
      <circle className="logo-circle" cx="137.9" cy="237" r="23" opacity="0">
        <animate attributeName="opacity" from="0" to="1" dur="1.5s" begin="0.5s" fill="freeze" />
      </circle>
      <circle className="logo-circle" cx="211.2" cy="110" r="23" opacity="0">
        <animate attributeName="opacity" from="0" to="1" dur="1.5s" begin="0.5s" fill="freeze" />
      </circle>
    </g>
    <text x="930" y="200" className="logo-text" textAnchor="middle" dominantBaseline="middle">
      Bindbridge
    </text>
  </svg>
);

export default AnimatedLogo;