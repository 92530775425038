import AnimatedLogo from '../components/AnimatedLogo';
import { Mail } from 'lucide-react';
import { SiGithub } from 'react-icons/si';

export default function HomePage() {
  return (
    <div className="logo-container">
      <AnimatedLogo className="logo" />
      <div className="icon-container">
        <a
          href="mailto:info@bindbridge.com"
          className="icon"
          aria-label="Contact us via email"
        >
          <Mail className="icon" />
        </a>
        <a
          href="https://github.com/bindbridge"
          className="icon"
          aria-label="Visit our GitHub"
        >
          <SiGithub className="icon" />
        </a>
      </div>
    </div>
  );
}
